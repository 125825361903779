import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// utils
import { fDateTime } from 'src/utils/format-time';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { fCurrency } from 'src/utils/format-number';
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { shortDateLabel } from 'src/components/custom-date-range-picker';
import {useCallback} from "react";
import {enqueueSnackbar} from "notistack";

import {useTranslation} from "react-i18next";
import {useAxStore} from "../../../store/axStore";
import {useCopyToClipboard} from "../../../hooks/use-copy-to-clipboard";

// ----------------------------------------------------------------------

export default function PixmeFolderItem({ folder,titlePhoto, onView, onEdit, onDelete,onRename,onCopy,small,onMoveFolderToFolder }) {
  const popover = usePopover();
  const { copy } = useCopyToClipboard();
  const { t, onChangeLang } = useTranslation()
  const axStore=useAxStore();
  const {
    id,
    name,
    slug,
    hash_id,
    files_count,
    subfolders_count,
    created_at_hard,
  } = folder;


  const onCopyText = useCallback(
    (text) => {
      if (text) {
        enqueueSnackbar(t('fldcard.sharing_link_copied_into_clipboard'));
        copy(text);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [copy]
  );

  const renderImages = (
    <Stack
      spacing={0.5}
      direction="row"
      sx={{
        p: (theme) => theme.spacing(1, 1, 0, 1),
      }}
    >
      <Stack flexGrow={1} sx={{ position: 'relative'}}>
        {/* renderPrice */}
        {/* renderRating */}
        {/* <Image alt={images[0]} src={images[0]} sx={{ borderRadius: 1, height: 164, width: 1 }} /> */}
        <Link component={RouterLink} href={`/dashboard/pixme/folder?fid=${slug}`} color="inherit">
        <Box
          component="img"

          src={`${process.env.PUBLIC_URL}/assets/images/fldr4.png`} // Path to your folder image
          alt="Folder"
          sx={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'cover', // Ensures the folder image covers the Stack
            borderRadius: 2, // Optional: add some rounding to the folder
          }}
        />
        </Link>

        {/* Custom Folder Title Photo */}
        {titlePhoto&&
          <Link component={RouterLink} href={`/dashboard/pixme/folder?fid=${slug}`} color="inherit">
        <img

          src={`${axStore.systemCurrentConfig.s3.s3_endpoint_preview}t_${titlePhoto}`} // Path to your title photo image
          alt={titlePhoto}
          style={{
            boxShadow: titlePhoto.endsWith('.png') ? 'none' : '0px 0px 14px 2px rgba(252,252,252,0.5)',
            borderRadius:'10px',
            position: 'absolute',
            top: '60%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // Centers the image in the folder
            maxWidth: '90%',
            maxHeight: '70%',
            objectFit: 'contain', // Ensures it fits by the longer side
          }}
        /></Link>}

          {/* <Link component={RouterLink} href={`/dashboard/pixme/folder?fid=${slug}`} color="inherit">
           <Image ratio="1/1" src={`${process.env.PUBLIC_URL}/assets/images/fldr.png`} sx={{ borderRadius: 1, height: 164, width: 1 }} />

          {/* <Image ratio="1/1" src={`${axStore.systemCurrentConfig.s3.s3_endpoint_preview}t_${title_photo_filename}`} sx={{ borderRadius: 1, height: 164, width: 1 }} />
        </Link> */}
      </Stack>
      {/*
      <Stack spacing={0.5}>
        <Image alt={images[1]} src={images[1]} ratio="1/1" sx={{ borderRadius: 1, width: 80 }} />
        <Image alt={images[2]} src={images[2]} ratio="1/1" sx={{ borderRadius: 1, width: 80 }} />
      </Stack>
      */}
    </Stack>
  );

  const renderTexts = (
    <ListItemText
      sx={{
        p: (theme) => theme.spacing(2.5, 2.5, 2, 2.5),
      }}
      /* primary={`${t('fldcard.adding_date')}: ${fDateTime(created_at_hard)}`} */
      primary={
        <>
          <CalendarMonthIcon style={{ marginRight: 4,fontSize:"14px" }} />
          {`${t('fldcard.adding_date')}: ${fDateTime(created_at_hard)}`}
        </>
      }
      secondary={<Link component={RouterLink} href={`/dashboard/pixme/folder?fid=${slug}`} color="inherit">
          {name}
        </Link>
      }
      primaryTypographyProps={{
        typography: 'caption',
        color: 'text.disabled',
      }}
      secondaryTypographyProps={{
        mt: 1,

        component: 'span',
        color: 'text.primary',
        typography: 'subtitle2',
      }}
    />
  );
const user=axStore.axCurrentUser;
  const renderInfo = (
    <Stack
      spacing={1.5}
      sx={{
        position: 'relative',
        p: (theme) => theme.spacing(0, 2.5, 2.5, 2.5),
      }}
    >
      <IconButton onClick={popover.onOpen} sx={{ position: 'absolute', bottom: 10, right: 8 }}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>

      {[
        {
          label: `${files_count > 0 ? `${files_count} ${t('files_gen')}` : ''}${((subfolders_count > 0)&&(files_count > 0)) ? `, `:''} ${subfolders_count > 0 ? `${subfolders_count} ${t('folders_gen').toLowerCase()}` : ''}`,
          icon: <Iconify icon="jam:pictures-f" sx={{ color: 'primary.main' }} />,
        },
      ].map((item) => (
        <Stack
          key={item.label}
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{ typography: 'caption' }}
        >
          {item.icon}
          {item.label}
        </Stack>
      ))}
    </Stack>
  );

  return (
    <>

      <Card sx={{ width: small ? 250 : 250}}>
        {renderImages}

        {renderTexts}

        { renderInfo }
      </Card>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 200 }}
      >
        {(user.department_role<3||user.ax_role<2)&&
        <MenuItem
          onClick={() => {
            popover.onClose();
            onRename();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          {t('fldcard.rename')}
        </MenuItem>
        }
        {(user.department_role<4||user.ax_role<2)&&
        <MenuItem
          onClick={() => {popover.onClose();onCopyText(`https://pixme.sk/share/?fid=${slug}`)}}>
          <Iconify icon="ph:link-light" />
          {t('fldcard.share')}
        </MenuItem>
        }
        {user.ax_role<1&&<>
        <MenuItem
          onClick={() => {
            popover.onClose();
            onEdit();
          }}
        >
          <Iconify icon="solar:download-bold" />
          {t('do_download')}
        </MenuItem>



        <MenuItem
          onClick={() => {
            popover.onClose();
            onCopy();
          }}
        >
          <Iconify icon="solar:move-to-folder-broken" />
          {t('fldcard.move')}
        </MenuItem>


        </>
        }


        <MenuItem
          onClick={() => {
            popover.onClose();
            onMoveFolderToFolder();
          }}
        >
          <Iconify icon="material-symbols:drive-file-move-outline" />
          {t('fldcard.move_to_folder')}
        </MenuItem>



        {(subfolders_count<1)&&(user.department_role<2||user.ax_role<2)&&
        <MenuItem
          onClick={() => {
            popover.onClose();
            onDelete();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('fldcard.delete_folder')}
        </MenuItem>}

      </CustomPopover>
    </>
  );
}

PixmeFolderItem.propTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onRename: PropTypes.func,
  onView: PropTypes.func,
  onMoveFolderToFolder: PropTypes.func,
  onCopy: PropTypes.func,
  folder: PropTypes.object,
  small: PropTypes.bool,
  titlePhoto: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
};
