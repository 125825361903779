import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import * as router from "lodash";
import { AuthContext } from './auth-context';
// import { isValidToken, setSession } from './utils';
 import {  setSession } from './utils';
import {useAxStore} from "../../../store/axStore";
import {paths} from "../../../routes/paths";
// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------
/*
const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    localStorage.removeItem('axjwt')
    // router.push('https://r65.sk')
    return {
      ...state,
      user: null,
    };
  }
  if (action.type === 'UPDATE_USER_AVATAR') {
    console.log('UPDATE_USER_AVATAR')
    return {
      ...state,
      user: {
        ...state.user,
        avatar_url: action.payload.avatar_url
      }
    };
  }
  return state;
};
*/
// ----------------------------------------------------------------------

const STORAGE_KEY = 'axjwt';

export function AuthProvider({ children }) {

    // const [state, dispatch] = useReducer(reducer, initialState);
    const axStore=useAxStore();

    const initialize = useCallback(async () => {
      // console.log('initialize started')
      try {
        // console.log('1')
        // const accessToken = sessionStorage.getItem(STORAGE_KEY);
         const jwt = localStorage.getItem(STORAGE_KEY);
        // console.log('1-jwt:',jwt)
        if (jwt) {
          // console.log('2')
           // setSession(accessToken);
          const headers={
            'Authorization': `Bearer ${jwt}`
          }
          const params={}
          const response = await axios.get(endpoints.auth.me,{
            headers,
            params
          });

          // const currentjwt=response.data.meta.jwt;
           const user=response.data.data.user_data;

          if(user) {
             // console.log('initializin authprovider is loggedin:',user)
             // console.log(response.data.data.deps)
            user.ax_role=parseInt(user.ax_role,10)
            axStore.setAxDepartments(response.data.data.deps)
            // console.log("response.data.data.deps")
            // console.log(response.data.data.deps)
            axStore.setAxCompanies(response.data.data.companies)
            axStore.setAxUserMenu(response.data.data.menu)
            axStore.setCurrentDepartment(response.data.data.cur_dep)
            axStore.setAxMotivational(response.data.data.motivational)
            axStore.setAxEventDepConfig(response.data.data.events_config)
            if(response.data.data.ax_settings.s3)
              axStore.setSystemCurrentConfig({ ...axStore.systemCurrentConfig, s3: response.data.data.ax_settings.s3 })


            localStorage.setItem('axjwt', response.data.meta.jwt);
            // router.push('dashboarde');
            axStore.setCurrentUser(user)
            axStore.setSystemAuthState('authenticated')
          }else{
            router.push('login');
            axStore.setSystemAuthState('unauthenticated')
          }
          // takto nastavim autocomplete option:
          // {"label":value.label,"id":value.id}
/*
          dispatch({
            type: 'INITIAL',
            payload: {
              user,
            },
          }); */
        } else {
          axStore.setSystemAuthState('unauthenticated')
          /* dispatch({
            type: 'INITIAL',
            payload: {
              user: null,
            },
          });

           */
          axStore.setCurrentUser(null)
        }
      } catch (error) {
        console.error(error);
        /* dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });

         */
        axStore.setCurrentUser(null)
        axStore.setSystemAuthState('unauthenticated')
      }


      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
/*
    useEffect(() => {
      // initialize();
    }, [initialize]);
*/

  useEffect(() => {

    initialize();

  }, [initialize]);



  // LOGIN
  const login = useCallback(async (email, password) => {
    const data = {
      email,
      password,
    };

    const response = await axios.post(endpoints.auth.login, data);

    if (response?.data?.error) {
      throw new Error(response.data.error.detail); // Throw an Error object
    }

    const user=response.data.data.user_data;
    user.ax_role=parseInt(user.ax_role,10)
    axStore.setAxDepartments(response.data.data.deps)
    axStore.setAxUserMenu(response.data.data.menu)
    axStore.setCurrentDepartment(response.data.data.cur_dep)
    axStore.setAxMotivational(response.data.data.motivational)
    axStore.setAxEventDepConfig(response.data.data.events_config)
    axStore.setCurrentUser(user)
    axStore.setSystemAuthState('authenticated')
    if(response.data.data.ax_settings.s3)
      axStore.setSystemCurrentConfig({ ...axStore.systemCurrentConfig, s3: response.data.data.ax_settings.s3 })

    // setSession(accessToken);
    localStorage.setItem('axjwt', response.data.meta.jwt);

    /* dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    }); */
    return(user.department_default_page);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    sessionStorage.setItem(STORAGE_KEY, accessToken);

    /* dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });

     */
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    axStore.setCurrentUser({})
    axStore.setSystemAuthState('unauthenticated')
    /* dispatch({
      type: 'LOGOUT',
    });
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----------------------------------------------------------------------

//   const checkAuthenticated = axStore.axCurrentUser ? 'authenticated' : 'unauthenticated';

  // const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: axStore.axCurrentUser,
      method: 'jwt',

      // loading: status === 'loading',
       // authenticated: status === 'authenticated',
       // unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [login, logout, register, axStore.axCurrentUser]
    // [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
