
import React, {useEffect, useState, useCallback} from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SendIcon from '@mui/icons-material/Send';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import {CircularProgress, InputAdornment, LinearProgress, OutlinedInput, Typography} from "@mui/material";
import axios from "axios";

import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import InputIcon from '@mui/icons-material/Input';
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import {useAxStore} from "../store/axStore";
import PixmeFolderItem from "../sections/pixme/view/pixme-folder-item";


export default function DeleteFolderModal({setDeleteFolderModalIsOpen,deleteFolderModalIsOpen,currentFolderId,currentFolderName,setJustDeletedFolder}) {
    const axStore=useAxStore()
    const user=axStore.axCurrentUser;
    const [actionStatus, setActionStatus] = React.useState(1); // 1-readz, 2-deleting, 3-done-succ, 4-done-error
    const [statusMessage, setStatusMessage] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
  const {
    selectedFiles_delete_multiple
  } = useAxStore()



    const handleClickOpen = () => {
      setDeleteFolderModalIsOpen(true);
    };
    const handleClose = () => {
      setDeleteFolderModalIsOpen(false);
      setActionStatus(1);
    };
  const deleteFiles = () => {

    setActionStatus(2)
    setStatusMessage('Odstraňujeme súbory...')
    const jwt=localStorage.getItem('axjwt')
    if(jwt){
      const form_data = new FormData();

      form_data.append('fid', currentFolderId);
      form_data.append('action', 'delete_pixme_files');

      axios.post('https://api.administrix.pro/v1/post/index.php',form_data,{
        headers: {'Content-Type': 'multipart/form-data','Authorization': `Bearer ${jwt}`}
      } )
        .then((response => {

          if(response.data.meta.jwt)
            localStorage.setItem('axjwt', response.data.meta.jwt);
          if(response.data.data.success) {
            if(response.data.data.success===1){
              setActionStatus(3)
              axStore.setNeedReloadCurrentFolder(true)
              setAlertMessage('Zložku sme úspešne odstránili z piXme.')
              setStatusMessage('')
               setJustDeletedFolder(response.data.data.deletedFid)
              if(response.data.data.deletedFiles)
              selectedFiles_delete_multiple(response.data.data.deletedFiles)


            }else{
              setActionStatus(4);
              setAlertMessage('Zložku sa nepodarilo odstrániť z piXme.')
            }
          }

        }))
        .catch(error => {
          if (error.response) {
//            setActionStatus(4)
//            setStatusMessage('Je nám ľúto, nastala chyba.')
            /*
            if(error.response.status===401){ // unauthentificated

            };

            if(error.response.status===403){ // unauthorized to make request

            };

             */
            // console.log(error.response.data.error);
          }
          setActionStatus(4)
          setAlertMessage('Je nám ľúto, nastala chyba na strane servera.')
          // console.log("any createdownload fetch api error");
          // setSendingStatus(4);
          // setSendingStatus(1);
          // console.log(error);
          // return (final_user)
        })


    }


  }

  const handleConfirmDelete = () => {
    // Add your folder deletion logic here
     console.log('Folder delete');
    // setOpenDeleteDialog(false);
    deleteFiles()
  };



    return (
        <>
            <Dialog open={deleteFolderModalIsOpen} onClose={handleClose}   fullWidth
                    maxWidth="xl">
                <DialogTitle>Odstrániť zložku</DialogTitle>

                <DialogContent>
                  {actionStatus===1&&<>
                    Ste si istí, že chcete odstrániť priečinok {currentFolderName}? Priečinok bude zmazaný vrátane jeho súborov.
                    Túto akciu nemožno vrátiť späť.
                  </>}

                  {actionStatus===2&& // in progress
                    <>
                      <LinearProgress/>
                      <Typography variant="caption">{statusMessage}</Typography>

                    </>
                  }

                  {actionStatus===3&& // done succ
                    <Alert severity="success">{alertMessage}</Alert>
                  }

                  {actionStatus===4&& // done err
                    <Alert severity="error">{alertMessage}</Alert>
                  }



                </DialogContent>



                <DialogActions>
                    {(actionStatus===1)&&<Button onClick={handleConfirmDelete} startIcon={<DeleteForeverIcon/>}>Vymazať</Button>}
                    {(actionStatus===1||actionStatus===3||actionStatus===4)&&<Button onClick={handleClose}>Zatvor</Button>}
                </DialogActions>
            </Dialog>

        </>

    );
}


  DeleteFolderModal.propTypes = {
    setDeleteFolderModalIsOpen: PropTypes.func,
      setJustDeletedFolder: PropTypes.func,
    deleteFolderModalIsOpen: PropTypes.bool,
    currentFolderId: PropTypes.number,
    currentFolderName: PropTypes.string
};
